import React, {useEffect, useLayoutEffect, useState} from "react";
import {Outlet, Link, useLocation, useParams} from "react-router-dom";
import $ from "jquery";
import Mmenu from "mmenu-js";

const Layout = () => {
    let params = useParams();
    let location = useLocation();

    function addScript(id, url){
        const old_script = document.getElementById(id);
        if(old_script){
            old_script.remove();
        }

        const script = document.createElement("script");
        script.setAttribute("id", id);
        script.src = url;
        //script.async = true;
        document.body.appendChild(script);

    }

    useEffect(() => {
        addScript("main-js", "/assets/js/main.js");
    }, [location]);

    return (
        <>
            <div className="cursor1"></div>
            <div className="cursor2"></div>
            <div className="preloader">
                <div className="loading">
                    <div className="bar bar1"></div>
                    <div className="bar bar2"></div>
                    <div className="bar bar3"></div>
                    <div className="bar bar4"></div>
                    <div className="bar bar5"></div>
                    <div className="bar bar6"></div>
                    <div className="bar bar7"></div>
                    <div className="bar bar8"></div>
                </div>
            </div>
            <div className="switcher__area">
                <div className="switcher__icon">
                    <button id="switcher_open"><i className="fa-solid fa-gear"></i></button>
                    <button id="switcher_close"><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="switcher__items">
                    <div className="switcher__item">
                        <div className="switch__title-wrap">
                            <h2 className="switcher__title">курсор</h2>
                        </div>
                        <div className="switcher__btn">
                            <select name="cursor-style" defaultValue={"2"} id="cursor_style">
                                <option value="1">За замовчуванням</option>
                                <option value="2">анімований</option>
                            </select>
                        </div>
                    </div>
                    <div className="switcher__item">
                        <div className="switch__title-wrap">
                            <h2 className="switcher__title">тема</h2>
                        </div>
                        <div className="switcher__btn mode-type wc-col-2">
                            <button className="active" data-mode="light">світла</button>
                            <button data-mode="dark">темна</button>
                        </div>
                    </div>
                    <div className="switcher__item">
                        <div className="switch__title-wrap">
                            <h2 className="switcher__title">Language Support</h2>
                        </div>
                        <div className="switcher__btn lang_dir wc-col-2">
                            <button className="active" data-mode="ltr">LTR</button>
                            <button data-mode="rtl">RTL</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="has-smooth" id="has_smooth"></div>
            <button id="scroll_top" className="scroll-top"><i className="fa-solid fa-arrow-up"></i></button>
            <header className="header__area">
                <div className="header__inner">
                    <div className="header__logo">
                        <Link to="">
                            <img className="logo-primary" src="/assets/imgs/logo/site-logo-white.png" alt="Site Logo" />
                            <img className="logo-secondary" src="/assets/imgs/logo/site-logo-white.png" alt="Moibile Logo" />
                        </Link>
                    </div>
                    <div className="header__nav-icon">
                        <button id="open_offcanvas">
                            <img src="/assets/imgs/icon/menu-white.png" alt="Menubar Icon" />
                            <p className="menu_text">Меню</p>
                        </button>
                    </div>
                    <div className="header__support">
                        <p>Наш телефон <a href="tel:+380443642444">044 364 24 44</a></p>
                    </div>
                </div>
            </header>
            <div className="offcanvas__area">
                <div className="offcanvas__body">
                    <div className="offcanvas__left">
                        <div className="offcanvas__logo">
                            <Link to="/">
                                <img src="/assets/imgs/logo/footer-logo-white.png" alt="Offcanvas Logo" />
                            </Link>
                        </div>
                        <div className="offcanvas__social">
                            <h3 className="social-title">Підпишись на нас</h3>
                            <ul>
                                <li><a href="#">Instagram</a></li>
                                <li><a href="#">Facebook</a></li>
                                <li><a href="#">Twitter</a></li>
                                <li><a href="#">YouTube</a></li>
                            </ul>
                            <h3 className="social-title">Наші контакти</h3>
                            <ul>
                                <li>Спортивна площа, 1, Київ, Украина, 01023</li>
                                <li><a href="tel:+380443642444" className="phone">+38 (044) 364 24 44</a></li>
                                <li><a href="mailto:info@itok.com.ua"> info@itok.com.ua</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="offcanvas__mid">
                        <div className="offcanvas__menu-wrapper">
                            <nav id="offcanvas__menu" className="offcanvas__menu">
                                <ul className="menu-anim">
                                    <li>
                                        <Link to="/">Головна</Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs">Блог</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Контакти</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="offcanvas__right transparent">
                        <img src="/assets/imgs/shape/11.png" alt="shape" className="shape-1" />
                        <img src="/assets/imgs/shape/12.png" alt="shape" className="shape-2" />
                    </div>
                    <div className="offcanvas__close">
                        <button type="button" id="close_offcanvas"><i className="fa-solid fa-xmark"></i></button>
                    </div>
                </div>
            </div>
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <main>
                        <Outlet />
                    </main>
                    <footer className="footer__area">
                        <div className="footer__top">
                            <div className="container footer-line"></div>
                            <img src="/assets/imgs/thumb/footer.jpg" alt="Footer Image" data-speed="0.5" />
                        </div>
                        <div className="footer__btm">
                            <div className="container">
                                <div className="row footer__row">
                                    <div className="col-xxl-12">
                                        <div className="footer__inner">
                                            <div className="footer__widget">
                                                <img className="footer__logo" src="/assets/imgs/logo/footer-logo-white.png" alt="Footer Logo" />
                                                <ul className="footer__social">
                                                    <li><a href="##"><span><i className="fa-brands fa-facebook-f"></i></span></a></li>
                                                    <li><a href="##"><span><i className="fa-brands fa-twitter"></i></span></a></li>
                                                    <li><a href="##"><span><i className="fa-brands fa-instagram"></i></span></a></li>
                                                    <li><a href="##"><span><i className="fa-brands fa-linkedin"></i></span></a></li>
                                                </ul>
                                            </div>

                                            <div className="footer__widget-2">
                                                <h2 className="footer__widget-title">Інформація</h2>
                                                <ul className="footer__link">
                                                    <li><Link to="/">Головна</Link></li>
                                                    <li><Link to="/blogs">Блог</Link></li>
                                                    <li><Link to="/contact">Контакти</Link></li>
                                                </ul>
                                            </div>

                                            <div className="footer__widget-3">
                                                <h2 className="footer__widget-title">Зв'яжися з нами</h2>
                                                <ul className="footer__contact">
                                                    <li>Спортивна площа, 1, Київ, Украина, 01023</li>
                                                    <li><a href="tel:+380443642444" className="phone">+38 (044) 364 24 44</a></li>
                                                    <li><a href="mailto:info@itok.com.ua"> info@itok.com.ua</a></li>
                                                </ul>
                                            </div>

                                            <div className="footer__widget-4">
                                                <h2 className="project-title">Хочеш новий проэкт?</h2>
                                                <div className="btn_wrapper">
                                                    <Link to="/contact" className={"wc-btn-primary btn-hover btn-item"}>
                                                        <span></span>зв'яжися з нами<i className="fa-solid fa-arrow-right"></i>
                                                    </Link>
                                                </div>
                                                <h3 className="contact-time">09 : 00 - 18 : 00</h3>
                                                <h4 className="contact-day">Понеділок - Пятниця</h4>
                                            </div>

                                            <div className="footer__copyright">
                                                <p>© 2023 - 2024 <br /> Всі права захищені.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
};

export default Layout;