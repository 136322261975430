import React from "react";

class Default extends React.Component{
    componentDidMount() {
        let cols = document.getElementsByClassName('offcanvas__area');
        for(let i = 0; i < cols.length; i++) {
            cols[i].style.opacity = '0';
            cols[i].style.visibility = 'hidden';
        }
    }
}

export default Default