const Touchpanels = (props) => {
    const { updateTitle } = props;

    return (<>
        {updateTitle("Tousch Panels")}
    <section className="portfolio__detail">
        <div className="portfolio__detail-top">
            <div className="container g-0 line pt-110 pb-130">
                <span className="line-3"></span>
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                        <div className="sec-title-wrapper">
                            <h2 className="sec-title animation__char_come">Tousch Panels </h2>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                        <div className="portfolio__detail-info">
                            <ul>
                                <li>Продукт <span>Мапа інформаційних маршрутів</span></li>
                                <li>Клієнт <span>ТРЦ Gulliver</span></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="portfolio__detail-thumb">
            <img src="/assets/imgs/portfolio/detail/panel_bg.JPG"
                 alt="Portfolio Thumbnail" data-speed="auto" />
        </div>
        <div className="portfolio__detail-content">
            <div className="container g-0 line pt-140">
                <span className="line-3"></span>
                <div className="block-content">
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                            <h2 className="portfolio__detail-title title-anim">Маршрут успіху!</h2>
                        </div>
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                            <div className="portfolio__detail-text">
                                <p>інноваційний інструмент, який допомагає відвідувачам торгового центру швидко і легко знайти необхідну інформацію</p>

                                <h3>Коротко про TouchPanels:</h3>
                                <ul>
                                    <li>Інтуїтивно зрозумілий інтерфейс</li>
                                    <li><span>Інформативність:</span> TouchPanels можна використовувати для відображення різної інформації, включаючи текст, зображення, відео та мультимедійні файли. Це дозволяє організаціям надавати користувачам актуальну і корисну інформацію у зручному для них форматі.
                                    </li>
                                </ul>
                                <br />
                                    <h3>Гнучкість:</h3>
                                    <ul>
                                        <li>TouchPanels можна налаштовувати відповідно до потреб організації. Це дозволяє створювати інтерактивні панелі, які відповідають конкретним вимогам організації.</li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center" style={{marginBottom: "50px"}}>
                    <div className="col-lg-6 col-md-12 img-col">
                        <div className="video-wrapper">
                            <video poster="/assets/imgs/portfolio/detail/panel_img1.png" id="screensaverVideo" loop="" autoplay="" muted="">
                                <source src="/assets/video/Intro-1.webm" type="video/webm" />
                                <source src="/assets/video/intro.webm" type="video/webm" />
                                <source src="/assets/video/intro.ogv" type="video/ogg" />
                                <source src="/assets/video/intro_original.Ogg" type="video/ogg" />
                                    Ваш браузер не підтримує HTML5
                            </video>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="portfolio__detail-text">
                        <h4>TouchPanels пропонують широкий спектр функцій, які можуть бути використані для різних цілей.   <br /> До основних функцій TouchPanels відносяться:</h4>
                    <br />
                    <ul>
                        <li><span>Відображення інформації:</span>TouchPanels можна використовувати для відображення різної інформації, включаючи текст, зображення, відео та мультимедійні файли.</li>
                        <li><span>Інтерактивність:
                        </span>TouchPanels дозволяють користувачам взаємодіяти з інформацією на екрані за допомогою сенсорного екрану. Це може включати такі дії, як натискання, прокручування та перетягування.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row align-items-center" style={{marginBottom: "50px"}} >
            <div className="col-lg-6 col-md-12">
                <div className="portfolio__detail-text">
                    <h3>Для кожного</h3>
                    <ul>
                        <li><span>Налаштування:</span>TouchPanels можна налаштовувати відповідно до потреб організації. Це дозволяє створювати інтерактивні панелі, які відповідають конкретним вимогам організації.</li>
                        <li><span>Застосування: </span> TouchPanels можуть використовуватися в різних сферах, включаючи освіту, бізнес, розваги та маркетинг.</li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 img-col">
                <img className="portfolio-img" src="/assets/imgs/portfolio/detail/touch_img5.jpg" alt="" />
            </div>
        </div>
        <h3 className="modern-title title-anim">Майбутнє вже настало</h3>
        <section className="panel-section">
            <div className="glass-panel">
                <div className="card">
                    <div className="logo">
                        <span className="circle circle1"></span>
                        <span className="circle circle2"></span>
                        <span className="circle circle3"></span>
                        <span className="circle circle4"></span>
                        <span className="circle circle5">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12.503 4.142l-.707-.707L13.3 1.932l.707.707zM10 1.134H9v2.833h1zm-2.86 2.3L5.637 1.933l-.707.707 1.503 1.503zm11.835 10.788a1.418 1.418 0 0 0-1.466-1.489 4.475 4.475 0 0 0-.693.064l-.042-.016A1.388 1.388 0 0 0 15.478 12a1.788 1.788 0 0 0-.587.064A1.84 1.84 0 0 0 13 11.06a2.768 2.768 0 0 0-1 .167V6.9a1.905 1.905 0 0 0-2-1.846A1.905 1.905 0 0 0 8 6.9v6.954L6.979 12.64a1.624 1.624 0 0 0-1.066-.733 1.775 1.775 0 0 0-1.264.161 1.478 1.478 0 0 0-.71.968 1.588 1.588 0 0 0 .167 1.137l2.154 4.38 3.32 4.064.773-.635-3.221-3.921-2.144-4.359a.619.619 0 0 1-.074-.446.485.485 0 0 1 .235-.322.796.796 0 0 1 .543-.051.708.708 0 0 1 .47.328L9 16.6V6.9a.908.908 0 0 1 1-.846.908.908 0 0 1 1 .846V14h1v-1.379c0-.373.336-.562 1-.562.374 0 1 .073 1 .562V14h1v-.563c0-.36.111-.47.478-.47.507 0 .522.684.522.762v1.063h1V13.76l-.005.002.005-.008v.006c.011-.005.362-.027.508-.027.129 0 .492.156.492.761.022.245 0 2.992 0 4.093a7.863 7.863 0 0 1-.463 2.657c-.16.421-.56 1.221-.56 1.221l.918.43s.272-.606.398-.925a7.32 7.32 0 0 0 .471-1.57A26.038 26.038 0 0 0 19 17.07z"></path><path fill="none" d="M0 0h24v24H0z"></path></g></svg>
                        </span>
                    </div>
                    <div className="glass"></div>
                    <div className="content">
                        <span className="title">TouchPanels</span>
                        <span className="text">зробіть свій бізнес більш ефективним</span>
                    </div>
                </div>
            </div>
            <div className="block-img-text">
                <div className="image-mask">
                    <div className="glass"></div>
                    <img src="/assets/imgs/portfolio/detail/panel_img1.png" alt="Portfolio Image" />
                </div>
                <div className="image-mask">
                    <div className="glass"></div>
                    <img src="/assets/imgs/portfolio/detail/touch_img4.JPG" alt="Portfolio Image" />
                </div>
                <p>
                    TouchPanels: підвищують інформативність та залучення клієнтів. </p>
            </div>
        </section>
    </div>
    </div>

</section>
    </>);
}

export default Touchpanels;