const Visitbook = (props) => {
    const { updateTitle } = props;

    return (<>
        {updateTitle("Visitbook - Система керування гостьовим доступом")}
        <section className="portfolio__detail">
            <div className="portfolio__detail-top">
                <div className="container g-0 line pt-110 pb-130">
                    <span className="line-3"></span>
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come">Visitbook </h2>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                            <div className="portfolio__detail-info">
                                <ul>
                                    <li>Продукт <span>Система керування гостьовим доступом</span></li>
                                    <li>Клієнт <span>ТРЦ Gulliver</span></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio__detail-thumb">
                <img src="/assets/imgs/portfolio/5/pf_bg2.jpg"
                     alt="Portfolio Thumbnail" data-speed="auto" />
            </div>

            <div className="portfolio__detail-content">

                <div className="container g-0 line pt-140">
                    <span className="line-3"></span>
                    <div className="block-content">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                <h2 className="portfolio__detail-title title-anim">Сезам, відкрийся!</h2>
                            </div>

                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                <div className="portfolio__detail-text">
                                    <p>Запрошуйте відвідувачів на робоче місце так, щоб вони не ставили під загрозу здоров'я, безпеку
                                        та відповідність вимогам вашої компанії.</p>

                                    <h3>Для колег:</h3>
                                    <ul>
                                        <li><span>Запрошуйте у веб-застосунку:</span> створюйте перепустки та талони для паркінгу.
                                            Надсилайте гостям на пошту та месенджери. Керуйте доступом та навантаження зустрічаючих
                                            спеціалістив.</li>
                                        <li><span>Підтверджуйте візит за допомогою чат-боту:</span> Впускайте бажаних візитерів,
                                            блокуйте небажаний, керуйте доступом до коворкінгів та зустрічайте кур'єрів зі свого телефону.
                                        </li>
                                    </ul>
                                    <br />
                                    <h3>Для гостей:</h3>
                                    <ul>
                                        <li><span>Реєстрація поза чергою:</span> прибувши на місце, реєструйтесь на ресепшені та миттєво
                                            проходьте до партнерів.</li>
                                        <li><span>Не треба шукати парковку:</span> разом із запрошенням компанія може надіслати
                                            погашення рахунку за паркінг.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Веб застосунок:</h3>
                                <ul>
                                    <li><span>Зручність і простота використання</span> VisitBook є простим у використанні як для бізнесу, так і для гостей. Бізнес може легко створювати запрошення та перепустки, а гості можуть легко зареєструватися та отримати доступ до офісу.</li>
                                    <li><span>Ефективність</span>VisitBook може допомогти бізнесу підвищити ефективність роботи ресепшені. Автоматизація завдань, таких як реєстрація гостей, може звільнити час співробітникам для виконання інших завдань.</li>
                                    <li><span>Можливість інтеграції з іншими системами</span>VisitBook можна інтегрувати з іншими системами, такими як CRM-інструмент або система управління персоналом. Це може допомогти бізнесу автоматизувати завдання та поліпшити ефективність.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img iphone" src="/assets/imgs/portfolio/detail/iPhone-Pro.png"
                                 alt="" style={{maxHeight: "600px", width: "auto"}} /></div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12 vid-col">
                            <div className="video-wrapper">
                                <video poster="/assets/video/main.mp4" muted="" autoPlay="" loop=""
                                       playsInline="" className="index-intro__video-tag" style={{maxHeight: "300px", width: "auto"}}>
                                    <source src="/assets/video/main.mp4" type="video/mp4" />Ваш браузер не підтримує
                                        HTML5
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Мобільний кіоск:</h3>
                                <ul>
                                    <li><span>Керування запрошеннями та перепустками</span>Функція запрошень дозволяє надсилати гостям електронні листи з інформацією про їхнє відвідування, включаючи дату, час та місце зустрічі. Запрошувані можуть відповісти на запрошення та завантажити свою перепустку на свій смартфон.</li>
                                    <li><span>Налаштовувати сценарії доступу до офісу</span>Функція сценаріїв доступу дозволяє налаштувати правила, які визначатимуть, як гості можуть отримати доступ до вашого офісу. Наприклад, ви можете дозволити гостям входити в офіс лише в певні дні та години або лише після того, як їх зустріне співробітник.</li>
                                    <li><span>Отримувати аналітику та хронологію візитів</span>VisitBook надає аналітику та хронологію візитів, які допоможуть вам відстежувати, хто відвідував ваш офіс, коли вони відвідували і як довго вони залишалися. Ця інформація може бути використана для поліпшення безпеки вашого офісу та для кращого розуміння ваших клієнтів.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}} >
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Telegram-bot:</h3>
                                <ul>
                                    <li><span>Покращення взаємодії з клієнтами:</span> Чат-бот забезпечує зручний і доступний спосіб для клієнтів зв'язуватися з вашим бізнесом. Він також може бути використаний для персоналізації взаємодії з клієнтами та надання їм більш цінного досвіду.</li>
                                    <li><span>Зниження витрат на підтримку клієнтів:</span> Чат-бот може автоматизувати більшість завдань з обслуговування клієнтів, таких як відповіді на типові запитання, вирішення простих проблем та обробка звичайних запитів. Це дозволяє вашій команді зосередитися на більш складних завданнях та покращує загальну ефективність роботи.</li>
                                    <li><span>Доступність 24/7:</span>Чат-бот може обслуговувати клієнтів цілодобово, що дозволяє їм отримувати потрібну їм інформацію та підтримку, коли вона їм потрібна.</li>
                                    <li><span>Персоналізація:</span>Чат-бот можна налаштувати таким чином, щоб він відповідав потребам і стилю вашого бізнесу. Ви можете використовувати чат-бот для персоналізації взаємодії з клієнтами і для того, щоб зробити їх більш цінними.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img" src="/assets/imgs/custom/chatbot.webp" alt="chatbot" />
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img" src="/assets/imgs/portfolio/5/take.webp" alt="" />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Реєстрація та друк тимчасових перепусток прямо на ресепшені</h3>
                                <ul>
                                    <li>При реєстрації на стійці, запрошувач одразу отримує повідомлення, а гість перепустку яка
                                        відкриє всі двері по маршруту до локації</li>
                                    <li><span>Зручність для гостей</span>Реєстрація та друк тимчасових перепусток прямо на ресепшені дозволить співробітникам ресепшені швидко та легко обслуговувати гостей, які не мають електронних перепусток. Це може звільнити час співробітникам для виконання інших завдань.</li>
                                    <li><span>Покращення безпеки
                        </span>Функція, яка дозволяє гостям отримувати перепустку, яка відкриє всі двері по маршруту до локації, може допомогти покращити безпеку вашого офісу. Це дозволить запобігти тому, щоб гості заходили в зони, до яких їм не дозволено доступ.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Паркінг за запрошенням</h3>
                                <ul>
                                    <li>При отриманні перепустки отримуйте також талон на погашення знижок на паркінг</li>
                                    <li>Ця функція дозволить вам надавати гостям доступ до парковки вашого офісу. Для цього вам потрібно буде створити правила доступу до парковки, які визначатимуть, які гості можуть отримувати доступ до парковки та за яких умов.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img"
                                 src="/assets/imgs/portfolio/5/parking.png"
                                 alt="" />
                        </div>
                    </div>
                    <h3 className="modern-title title-anim">Основні переваги</h3>
                    <div className="modern-timeline">
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa-sharp fa-regular fa-comments"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Комунікації</span> Ви витрачаєте менше часу на комунікації з відвідувачами. Відвідувачу не потрібно витрачати час на пошук інформації.</div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa-solid fa-battery-three-quarters"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Економія зусиль</span> Запрошення завжди коректні та мають детальну інформацію про візит.</div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa-solid fa-laptop"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Контроль</span>Керування на всіх єтапах візиту, та вирішення питань</div>
                        </div>
                        <div className="timeline-item">
                            <div class="timeline-item__icon"><i class="fa-solid fa-screwdriver-wrench"></i></div>
                            <div class="timeline-item__progress"></div>
                            <div class="timeline-item__text"><span>Стиль</span> Інновацій ність та системність, простий зрозумілий корпоративний стиль.</div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-item__icon"><i class="fa-solid fa-handshake"></i></div>
                            <div class="timeline-item__progress"></div>
                            <div class="timeline-item__text"><span>Успіх</span> Успішні переговори починаються з запрошення. </div>
                        </div>
                    </div>
                    <div class="block-img-text">
                        <img src="/assets/imgs/portfolio/detail/BC1.jpg" alt="Portfolio Image" />
                        <img src="/assets/imgs/portfolio/detail/BC2.jpg" alt="Portfolio Image" />
                        <p> Компанії обирають VisitBook, тому що вона проста у використанні, безпечна та ефективна. </p>
                    </div>
                </div>
            </div>

        </section>
    </>);
}

export default Visitbook;