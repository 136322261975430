import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Parser } from "html-to-react";
import Air from "./projects/air";
import Cabinet from "./projects/cabinet";
import Crm from "./projects/crm";
import Touchpanels from "./projects/touchpanels";
import Videoforge from "./projects/videoforge";
import Visitbook from "./projects/visitbook";

const ProjectPage = (props) => {
    let params = useParams();

    const getProject = () => {
        switch (params.slug){
            case "air":
                return <Air updateTitle={props.updateTitle} />;
            case "cabinet":
                return <Cabinet updateTitle={props.updateTitle} />;
            case "crm":
                return <Crm updateTitle={props.updateTitle} />;
            case "touchpanels":
                return <Touchpanels updateTitle={props.updateTitle} />;
            case "videoforge":
                return <Videoforge updateTitle={props.updateTitle} />;
            case "visitbook":
                return <Visitbook updateTitle={props.updateTitle} />;
            default:
                return <Air updateTitle={props.updateTitle} />;
        }
    }

    return (
        <>
            {getProject()}
        </>
    );
}

export default ProjectPage;