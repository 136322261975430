const Cabinet = (props) => {
    const { updateTitle } = props;

    return (<>
        {updateTitle("Кабінет орендаря")}
        <section className="portfolio__detail">
            <div className="portfolio__detail-top">
                <div className="container g-0 line pt-110 pb-130">
                    <span className="line-3"></span>
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come">Tenant Cabinet </h2>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                            <div className="portfolio__detail-info">
                                <ul>
                                    <li>Продукт <span>кабінет орендаря</span></li>
                                    <li>Клієнт <span>Anbud</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio__detail-thumb">
                <img src="/assets/imgs/portfolio/detail/tenant_about_bg.jpg" alt="Portfolio Thumbnail"
                     data-speed="auto"/>
            </div>
            <div className="portfolio__detail-content">
                <div className="container g-0 line pt-140">
                    <span className="line-3"></span>
                    <div className="block-content">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                <h2 className="portfolio__detail-title title-anim">Зручність та єфективність.</h2>
                            </div>
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                <div className="portfolio__detail-text">
                                    <p>Tenant Cabinet — це веб-платформа, яка надає орендарям доступ до інформації та
                                        послуг, пов’язаних з їхнім житлом. Платформа доступна 24/7 і може
                                        використовуватися на будь-якому пристрої з доступом до Інтернету</p>
                                    <h3>Для користувачів:</h3>
                                    <ul>
                                        <li>Tenant Cabinet пропонує ряд переваг для орендарів, включаючи:<span>Доступ до інформації про житло:</span> орендарі
                                            можуть переглядати інформацію про свою оренду, включаючи умови оренди,
                                            платежі за оренду та платежі за послуги.
                                            Надсилайте гостям на пошту та месенджери. Керуйте доступом та навантаження
                                            зустрічаючих
                                            спеціалістив.
                                        </li>
                                        <li><span>Зручне управління платежами: </span> орендарі можуть легко вносити
                                            платежі за оренду та послуги в режимі онлайн.
                                        </li>
                                        <li><span>Зв'язок з менеджером: </span> орендарі можуть легко зв’язатися з
                                            менеджером своєї будівлі, щоб повідомити про проблеми або задати питання.
                                        </li>
                                    </ul>
                                    <br/>
                                    <h3>Для менеджерів:</h3>
                                    <ul>
                                        <li><span>Покращене спілкування з орендарями:</span> менеджери можуть легко
                                            спілкуватися з орендарями за допомогою платформи, що може допомогти
                                            покращити обслуговування клієнтів.
                                        </li>
                                        <li><span>Ефективніше управління контрактами:</span> менеджери можуть легко
                                            керувати контрактами оренди та іншими документами за допомогою платформи, що
                                            може допомогти скоротити витрати на паперову роботу.
                                        </li>
                                        <li><span>Збільшена прозорість: </span>орендарі можуть легко переглядати
                                            інформацію про свою оренду та платежі за оренду, що може допомогти підвищити
                                            прозорість та задоволеність клієнтів.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Веб застосунок:</h3>
                                <ul>
                                    <li><span>Доступність</span> веб застосунок доступний на будь-якому пристрої з
                                        доступом до Інтернету, включаючи комп’ютери, планшети та смартфони
                                    </li>
                                    <li><span>Зручність</span>веб застосунок простий у використанні та не вимагає
                                        спеціальної підготовки
                                    </li>
                                    <li><span>Ефективність </span> веб застосунок дозволяє менеджерам швидко та легко
                                        виконувати завдання, пов'язані з управлінням орендою
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img iphone" src="/assets/imgs/portfolio/detail/tenant_iphone.png"
                                 alt=""  style={{maxHeight: "600px", width: "auto"}} /></div>
                    </div>
                    <h3 className="modern-title title-anim">Основні переваги</h3>
                    <div className="tenat-service">
                        <div className="grid">
                            <div className="grid-card">
                    <span className="icon">
                      <i className="fas fa-sitemap"></i>
                    </span>
                                <h4>Доступ до інформації </h4>
                                <p>
                                    орендарі можуть переглядати інформацію про свою оренду
                                </p>
                                <div className="shine"></div>
                                <div className="background">
                                    <div className="line line-1"></div>
                                    <div className="line line-2"></div>
                                    <div className="line line-3"></div>
                                    <div className="line line-4"></div>
                                </div>
                            </div>
                            <div className="grid-card">
                    <span className="icon">
                      <i className="fas fa-users-cog"></i>
                    </span>
                                <h4>Зручне управління </h4>
                                <p>
                                    орендарі можуть легко вносити платежі за оренду та послуги в режимі онлайн
                                </p>
                                <div className="shine"></div>
                                <div className="background">
                                    <div className="line line-1"></div>
                                    <div className="line line-2"></div>
                                    <div className="line line-3"></div>
                                    <div className="line line-4"></div>
                                </div>
                            </div>
                            <div className="grid-card">
                    <span className="icon">
                      <i className="fas fa-headset"></i>
                    </span>
                                <h4>Зв'язок з менеджером </h4>
                                <p>
                                    орендарі можуть легко зв’язатися з менеджером своєї будівлі, щоб повідомити про
                                    проблеми або задати питання
                                </p>
                                <div className="shine"></div>
                                <div className="background">
                                    <div className="line line-1"></div>
                                    <div className="line line-2"></div>
                                    <div className="line line-3"></div>
                                    <div className="line line-4"></div>
                                </div>
                            </div>
                            <div className="grid-card">
                    <span className="icon">
                      <i className="fas fa-shield-alt"></i>
                    </span>
                                <h4>Безпека та конфіденційність</h4>
                                <p>
                                    інформація про орендарів зберігається в безпечному середовищі.
                                </p>
                                <div className="shine"></div>
                                <div className="background">
                                    <div className="line line-1"></div>
                                    <div className="line line-2"></div>
                                    <div className="line line-3"></div>
                                    <div className="line line-4"></div>
                                </div>
                            </div>
                            <div className="grid-card">
                    <span className="icon">
                      <i className="far fa-thumbs-up"></i>
                    </span>
                                <h4>Зручність використання </h4>
                                <p>
                                    платформа доступна на будь-якому пристрої з доступом до Інтернету
                                </p>
                                <div className="shine"></div>
                                <div class="background">
                                    <div class="line line-1"></div>
                                    <div class="line line-2"></div>
                                    <div class="line line-3"></div>
                                    <div class="line line-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block-img-text">
                        <img src="/assets/imgs/portfolio/detail/tenant_img3.jpg" alt="Portfolio Image" />
                        <img src="/assets/imgs/portfolio/detail/tenant_img2.jpg" alt="Portfolio Image" />
                        <p> Tenant Cabinet — це потужний інструмент, який може допомогти орендарям та менеджерам
                            покращити свій досвід. Платформа пропонує ряд переваг, які можуть допомогти
                            підвищити ефективність, залучення клієнтів та прибутковість. </p>
                    </div>
                </div>
            </div>
        </section>
    </>
);
}

export default Cabinet;