import Default from "../components/Default";
import { Link } from "react-router-dom";
import React from "react";
import Portfolio from "../components/Portfolio";

class Home extends Default{
    componentDidMount() {
        this.props.updateTitle("Головна сторінка");
        super.componentDidMount();
    }

    render() {
        return (
            <>
                <section className="hero__area-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="hero__inner-3">
                                    <div className="sec-title-wrapper">
                                        <h2 className="sec-sub-title">Agency</h2>
                                        <h3 className="sec-title title-left">ITOK</h3>
                                        <h4 className="sec-title title-right">development</h4>
                                    </div>
                                    <div className="hero__text-3">
                                        <p className="hero__text-animation">Технології, які працюють</p>
                                    </div>
                                    <div className="scroll-down">
                                        <button><img src="assets/imgs/icon/arrow-down-sm.png" alt="arrow icon" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="video-info">
                            <div className="video-intro">
                                <input id="video_check" type="checkbox" />
                                <div className="intro-title">
                                    <h4 className="video-title">Дивитись <span>відео</span></h4>
                                    <h4 className="video-title close-video-title">закрити <span>відео</span></h4>
                                </div>
                                <div className="video">
                                    <video src="assets/video/code.mp4" loop muted autoPlay playsInline></video>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="hero3-img-ani">
                        <img src="assets/imgs/hero/3/1.jpg" alt="Hero Image" className="hero3-img" />
                    </div>
                </section>
                <section className="brand__area">
                    <div className="container pt-140 pb-140">
                        <div className="row">
                            <div className="col-xxl-12">
                                <h2 className="brand__title-3 title-anim">ми допомагаємо нашим клієнтам впроваджувати готові IT-рішення
                                </h2>
                                <div className="brand__list__main">
                                    <div className="brand__item-2 fade_bottom">
                                        <img src="assets/imgs/brand/gulliver.svg" alt="Brand Logo" />
                                    </div>
                                    <div className="brand__item-2 fade_bottom">
                                        <img src="assets/imgs/brand/zuma.svg" alt="Brand Logo" />
                                    </div>
                                    <div className="brand__item-2 fade_bottom">
                                        <img src="assets/imgs/brand/mercato.svg" alt="Brand Logo" />
                                    </div>
                                    <div className="brand__item-2 fade_bottom">
                                        <img src="assets/imgs/brand/anbud.svg" alt="Brand Logo" />
                                    </div>
                                    <div className="brand__item-2 fade_bottom">
                                        <img src="assets/imgs/brand/eldorado.svg" alt="Brand Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about__area-3">
                    <div className="container pt-140 pb-110">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="about__img-3">
                                    <img src="assets/imgs/about/3/1.jpg" alt="About Thumbnail" data-speed="auto" />
                                </div>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-sub-title title-anim">Хто ми</h2>
                                    <h3 className="sec-title title-anim">Ми it Компанія ,</h3>
                                </div>
                                <div className="sec-text-wrapper">
                                    <div className="sec-text text-anim">
                                        <p>яка надає послуги з розробки програмного забезпечення, впровадження IT-рішень та підтримки.
                                            Ми допомагаємо нашим клієнтам досягати їхніх бізнес-цілей за допомогою інноваційних технологій.
                                        </p>
                                        <div className="btn_wrapper">
                                            <a className="wc-btn-light btn-hover btn-item" href="#"><span></span> Детальніше <i
                                                className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero__area-5">
                    <img src="assets/imgs/hero/5/hero-bg-5.png" alt="Shape Image" />
                </section>
                <Portfolio />
                <section className="cta__area">
                    <div className="container line pb-110">
                        <div className="line-3"></div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="cta__content">
                                    <p className="cta__sub-title">Створюй з нами</p>
                                    <h2 className="cta__title title-anim">Ми хочемо дізнатися більше про ваш проєкт</h2>
                                    <div className="btn_wrapper">
                                        <Link to="/contact" className="wc-btn-primary btn-hover btn-item">
                                            <span></span>Зв'язатися з нами <i
                                            className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="service__area-3 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="sec-title-wrapper pt-130 text-anim">
                                    <h2 className="sec-sub-title title-anim">Послуги</h2>
                                    <h3 className="sec-title title-anim">Сервіси, <br /> що впроваджуємо</h3>
                                    <p>Ми постійно працюємо над розробкою нових програм та впровадженням інноваційних технологій. Ми
                                        прагнемо допомагати нашим клієнтам досягати успіху за допомогою наших програм.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xx-12">
                                <div className="service__list-3">
                                    <div className="service__item-3 service_animation">
                                        <h3><a href="#" className="service__title-3">Jira</a></h3>
                                        <div className="service__content-3">
                                            <p>Система управління завданнями для команд, які працюють над проектами
                                            </p>
                                            <ul className="">
                                                <li>+ Організація роботи команди</li>
                                                <li>+ Відстеження прогресу</li>
                                                <li>+ Один з найвідоміших таск менеджерів</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/jira.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service__item-3 service_animation">
                                        <h3><a href="#" className="service__title-3 ">Confluence </a></h3>
                                        <div className="service__content-3">
                                            <p>Платформа для створення і обміну документами, кодом та іншими файлами
                                            </p>
                                            <ul className="">
                                                <li>+ Вся інформація в одному місці</li>
                                                <li>+ Ефективна комунікація</li>
                                                <li>+ Корисний функціонал</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>

                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/сonfluence.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service__item-3 service_animation">
                                        <h3 className=""><a href="#" className="service__title-3">GLPI</a></h3>
                                        <div className="service__content-3">
                                            <p>Система управління IT-інфраструктурою
                                            </p>
                                            <ul className="">
                                                <li>+ Управління активами</li>
                                                <li>+ Робота з запитами та іншими завданнями</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>

                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/GLPI.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service__item-3 service_animation">
                                        <h3 className=""><a href="#" className="service__title-3">Moodle</a></h3>
                                        <div className="service__content-3">
                                            <p>Система управління навчанням.
                                            </p>
                                            <ul className="">
                                                <li>+ Створення і керування курсами</li>
                                                <li>+ Відстеження прогресу студентів</li>
                                                <li>+ Надання зворотного зв'язку</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/Moodle.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service__item-3 service_animation">
                                        <h3 className=""><a href="#" className="service__title-3">Zabbix</a></h3>
                                        <div className="service__content-3">
                                            <p>Система моніторингу IT-інфраструктури
                                            </p>
                                            <ul className="">
                                                <li>+ Відстеження стану обладнання</li>
                                                <li>+ Мережі та програмного забезпечення</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/Zabbix.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service__item-3 service_animation">
                                        <h3 className=""><a href="#" className="service__title-3">Sentry</a></h3>
                                        <div className="service__content-3">
                                            <p>Система відстеження помилок
                                            </p>
                                            <ul className="">
                                                <li>+ Відстеження помилок в програмному забезпеченні</li>
                                                <li>+ Швидке вирішення багів</li>
                                            </ul>
                                        </div>
                                        <div className="service__btn-3">
                                            <div className="btn_wrapper">
                                                <a href="#" className="wc-btn-black btn-hover btn-item"><span></span> Деталі
                                                    <i className="fa-solid fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                        {/*<div className="service__hover-3"*/}
                                        {/*     style="background: url(assets/imgs/service/1/sentry.png)50% / 180px no-repeat;"></div>*/}
                                    </div>
                                    <div className="service3__img-wrap">
                                        <div className="service3__img"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="testimonial__area-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="swiper testimonial__slider-3">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide testimonial__slide-3">
                                            <p>Розроблена інтеграція з fleet management system дозволила спростити контроль списання ПММ і
                                                істотно знизити витрати на паливо</p>
                                            <h2 className="client__name-3">Андрій Єпішов</h2>
                                            <h3 className="client__role-3">директор "Транслогістіка"</h3>
                                        </div>
                                        <div className="swiper-slide testimonial__slide-3">
                                            <p>Ми співпрацюємо з компанією ITOK вже кілька років. За цей час ми розробили з ними кілька
                                                програмних продуктів, які допомогли нашому бізнесу значно поліпшити свою роботу.
                                                Компанія має команду досвідчених і кваліфікованих розробників програмного забезпечення, які
                                                використовують найновіші технології для створення високоякісного програмного забезпечення.
                                                <br />Ми рекомендуємо компанію всім, кому потрібно дійсно високоякісне програмне забезпечення.
                                            </p>
                                            <h2 className="client__name-3">Андрій Запорожець</h2>
                                            <h3 className="client__role-3">фінансовий директор</h3>
                                        </div>
                                        <div className="swiper-slide testimonial__slide-3">
                                            <p>"Компанія допомогла нам розробити нове програмне забезпечення для автоматизації наших
                                                бізнес-процесів. Продукт, який вони розробили, відповідає всім нашим вимогам і працює
                                                бездоганно.
                                                Ми були дуже задоволені співпрацею з компанією ITOK і рекомендуємо її будь-кому, хто прагне
                                                максимальної якості.
                                            </p>
                                            <h2 className="client__name-3">Сергій Корніяченко</h2>
                                            <h3 className="client__role-3">менеджер</h3>
                                        </div>
                                        <div className="swiper-slide testimonial__slide-3">
                                            <p>Ми звернулися до компанії ITOK з проханням розробити для нас новий сайт.
                                                Команда проробила чудову роботу.
                                                Сайт відповідає всім нашим вимогам і виглядає чудово.</p>
                                            <h2 className="client__name-3">Олена Коваль</h2>
                                            <h3 className="client__role-3">project manager</h3>
                                        </div>
                                    </div>
                                    <div className="next-button swipper-btn"><i className="fa-solid fa-arrow-left"></i></div>
                                    <div className="prev-button swipper-btn"><i className="fa-solid fa-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial__images-3 animation_image_zoom">
                        <img src="assets/imgs/testimonial/3/1.jpg" alt="testimonial Image" className="testimonial3__img" />
                        <img src="assets/imgs/testimonial/3/2.jpg" alt="testimonial Image" className="testimonial3__img-2" />
                        <img src="assets/imgs/testimonial/3/3.jpg" alt="testimonial Image" className="testimonial3__img-3" />
                        <img src="assets/imgs/testimonial/3/4.jpg" alt="testimonial Image" className="testimonial3__img-4" />
                        <img src="assets/imgs/testimonial/3/5.jpg" alt="testimonial Image" className="testimonial3__img-5" />
                        <img src="assets/imgs/testimonial/3/6.jpg" alt="testimonial Image" className="testimonial3__img-6" />
                    </div>
                </section>
                <section className="workflow__area-3">
                    <div className="workflow__wrapper-3">
                        <div className="choose-wrapper wf_panel">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-12">
                                        <div className="choose-title-wrapper">
                                            <h2 className="choose-title title-anim">Чому <br /> обирають нас</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="research__area wf_panel pt-150">
                            <div className="container inner_content">
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                                        <div className="sec-title-wrapper">
                                            <h2 className="sec-sub-title">Чому <br /> обирають нас</h2>
                                            <h3 className="sec-title">Компанія "ITOK"</h3>
                                            <p>це надійний партнер, який допоможе вам розробити високоякісне програмне забезпечення.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                                        <div className="research__list">
                                            <div className="research__item">
                                                <div className="research__number">
                                                    <span>100%</span>
                                                </div>
                                                <div className="research__info">
                                                    <h4 className="research__title">Досвідчена команда</h4>
                                                    <p>Ми працюємо в галузі програмування понад 10 років і маємо досвід у розробці різних типів
                                                        програмного забезпечення</p>
                                                </div>
                                            </div>

                                            <div className="research__item">
                                                <div className="research__number">
                                                    <span>90%</span>
                                                </div>
                                                <div className="research__info">
                                                    <h4 className="research__title">Сучасні технології</h4>
                                                    <p>Ми використовуємо найновіші технології для створення програмного забезпечення, яке
                                                        відповідає вашим потребам</p>
                                                </div>
                                            </div>

                                            <div className="research__item">
                                                <div className="research__number">
                                                    <span>100%</span>
                                                </div>
                                                <div className="research__info">
                                                    <h4 className="research__title">Персональний підхід</h4>
                                                    <p>Ми завжди приділяємо увагу індивідуальним вимогам і створюємо програмне забезпечення, яке
                                                        відповідає вашим цілям.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="counter__area-3 wf_panel">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-12">
                                        <div className="sec-title-wrapper">
                                            <h2 className="sec-sub-title">Чому <br /> обирають нас</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                                        <div className="counter__wrapper-3">
                                            <div className="counter__item-3">
                                                <h2 className="counter__number">+500</h2>
                                                <p>Закінчених <br />проєктів</p>
                                            </div>
                                            <div className="counter__item-3">
                                                <h2 className="counter__number">90%</h2>
                                                <p>Щасливих <br />клієнтів</p>
                                            </div>
                                            <div className="counter__item-3">
                                                <h2 className="counter__number">15</h2>
                                                <p>Років <br />досвіду</p>
                                            </div>
                                            <div className="counter__item-3">
                                                <h2 className="counter__number">100%</h2>
                                                <p>Готовність <br />програмувати</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                        <div className="counter__img-3">
                                            <img src="assets/imgs/thumb/counter-3.png" alt="Counter Image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cta__area-3 wf_panel">
                            <div className="container pt-150 pb-150">
                                <div className="row">
                                    <div className="col-xxl-12">
                                        <div className="cta__content-3">
                                            <p className="cta__sub-title-2">Маєш ідею на проект?</p>
                                            <h2 className="cta__title-2">Давайте зробимо щось чудове разом!</h2>
                                            <div className="btn_wrapper">
                                                <Link to="/contact" className="wc-btn-black btn-hover btn-item">
                                                    <span></span>Зв'яжися <br />з нами <i className="fa-solid fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Home;