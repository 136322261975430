const Crm = (props) => {
    const { updateTitle } = props;

    return (<>
        {updateTitle("Air CRM - cистема для взаємодії з клієнтами")}
        <section className="portfolio__detail">
            <div className="portfolio__detail-top">
                <div className="container g-0 line pt-110 pb-130">
                    <span className="line-3"></span>

                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come">Air CRM </h2>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                            <div className="portfolio__detail-info">
                                <ul>
                                    <li>Продукт <span>Система для взаємодії з клієнтами</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio__detail-thumb">
                <img src="/assets/imgs/portfolio/detail/crm_bg.jpg"
                     alt="Portfolio Thumbnail" data-speed="auto" />
            </div>
            <div className="portfolio__detail-content">
                <div className="container g-0 line pt-140">
                    <span className="line-3"></span>
                    <div className="block-content">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                <h2 className="portfolio__detail-title title-anim">Простота та Надійність</h2>
                            </div>
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                <div className="portfolio__detail-text">
                                    <p>сучасна система управління взаємовідносинами з клієнтами, розроблена для малих і середніх підприємств. </p>
                                    <h3>Основні функції AIR CRM:</h3>
                                    <ul className="crm-list">
                                        <p><span>Автоматизація продажів:</span></p>
                                        <li>Керівництво продажами
                                        </li>
                                        <li>Ведення ділових переговорів</li>
                                        <li>Закриття угод</li>
                                    </ul>
                                    <ul className="crm-list">
                                        <p><span>Автоматизація маркетингу:</span></p>
                                        <li>Управління маркетинговими кампаніями</li>
                                        <li>Маркетингові дослідження</li>
                                        <li>Веб-аналітика</li>
                                    </ul>
                                    <ul className="crm-list">
                                        <p><span>Автоматизація обслуговування клієнтів:</span></p>
                                        <li>Управління запитами клієнтів</li>
                                        <li>Рішення проблем клієнтів</li>
                                        <li>Клієнтська підтримка</li>
                                        <p><span>Аналіз даних:</span></p>
                                        <li>Відстеження продажів</li>
                                        <li>Аналіз ефективності маркетингових кампаній</li>
                                        <li>Аналіз поведінки клієнтів</li>
                                        <p><span>Прийняття рішень</span></p>
                                        <li>Генерація звітів</li>
                                        <li>Порівняння показників</li>
                                        <li>Прогнозування продажів</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center" style={{marginBottom: "50px"}} >
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Веб застосунок:</h3>
                                <ul className="crm-list">
                                    <li><span>Зручність і простота використання</span> AIR CRM — це потужний інструмент, який може допомогти малим і середнім підприємствам підвищити ефективність продажів, маркетингу та обслуговування клієнтів. </li>
                                    <li><span>Ефективність</span> Система пропонує широкий спектр функцій і можливостей, які можуть бути адаптовані до потреб конкретного підприємства.</li>
                                    <li><span>Можливість інтеграції з іншими системами</span> Система дозволяє автоматизувати процеси продажів, маркетингу та обслуговування клієнтів, а також надає широкий спектр інструментів для аналізу даних і прийняття рішень.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img iphone" src="/assets/imgs/portfolio/detail/iphone_crm.png"
                                 alt="" style={{maxHeight: "600px", width: "auto"}} /></div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}} >
                        <div className="col-lg-6 col-md-12 vid-col">
                            <div className="video-wrapper">
                                <video poster="/assets/video/crm_video.mp4" muted="" autoPlay="" loop=""
                                       playsInline="" className="index-intro__video-tag" style={{maxHeight: "300px" , width: "auto"}} >
                                    <source src="/assets/video/crm_video.mp4" type="video/mp4" />Ваш браузер не підтримує
                                        HTML5
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Переваги використання AIR CRM:</h3>
                                <ul className="crm-list">
                                    <p>Покращення ефективності продажів</p>
                                    <li>Автоматизація рутинних завдань</li>
                                    <li>Збільшення продуктивності співробітників</li>
                                    <li>Підвищення якості обслуговування клієнтів</li>
                                </ul>
                                <ul className="crm-list">
                                    <p>Поліпшення ефективності маркетингу</p>
                                    <li>Точніший таргетинг</li>
                                    <li>Збільшення конверсії</li>
                                    <li>Покращення ROI</li>
                                </ul>
                                <ul className="crm-list">
                                    <p>Поліпшення ефективності обслуговування клієнтів</p>
                                    <li>Швидше вирішення проблем</li>
                                    <li>Збільшення задоволеності клієнтів</li>
                                </ul>
                                <ul className="crm-list">
                                    <p>Підвищення прибутковості</p>
                                    <li>Збільшення продажів</li>
                                    <li>Зниження витрат</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <h3 className="modern-title title-anim">Основні переваги</h3>
                    <div className="modern-timeline crm-timeline">
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa fa-check-square" aria-hidden="true"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Автоматизація продажів</span> Заощадження часу та зусиль. Покращення якості обслуговування клієнтів</div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa fa-tachometer" aria-hidden="true"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Єфективність маркетингу</span> Точний таргетинг при збільшеній конверсії та покращеному ROI</div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa fa-users" aria-hidden="true"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Обслуговування клієнтів</span>Швидше вирішення проблем, задоволені клієнти</div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-item__icon"><i className="fa fa-flask" aria-hidden="true"></i></div>
                            <div className="timeline-item__progress"></div>
                            <div className="timeline-item__text"><span>Збір та аналіз даних</span> Глибоке розуміння клієнтів, прийняття обґрунтованих рішень</div>
                        </div>
                        <div class="timeline-item">
                            <div class="timeline-item__icon"><i class="fa fa-retweet" aria-hidden="true"></i></div>
                            <div class="timeline-item__progress"></div>
                            <div class="timeline-item__text"><span>Гнучкість та масштабованість</span> Підходить для підприємств різних розмірів і галузей</div>
                        </div>
                    </div>
                    <div class="block-img-text">
                        <img src="/assets/imgs/portfolio/detail/crm_photo1.jpg" alt="Portfolio Image" />
                        <img src="/assets/imgs/portfolio/detail/crm_photo2.jpg" alt="Portfolio Image" />
                        <p> AIR CRM — це потужний інструмент, який може допомогти малим і середнім підприємствам підвищити ефективність продажів, маркетингу та обслуговування клієнтів. </p>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Crm;