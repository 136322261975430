import React from "react";
import { Link } from "react-router-dom";

const Porfolio = () => {
    return (
        <>
            <section className="portfolio__area-6">
                <div className="container line pt-100 pb-140">
                    <span className="line-3"></span>
                    <div className="zi-9">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6">
                                <div className="sec-title-wrapper portfolio__title-wrap-6">
                                    <div className="">
                                        <h2 className="sec-sub-title animation__char_come">Наші</h2>
                                        <h3 className="sec-title animation__char_come_long">Роботи</h3>
                                        <p>Ознайомтеся з нашими останніми проектами ІТ, які допомагають нашим клієнтам досягти успіху.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-8 col-xl-7 col-lg-7 col-md-6">
                                <div className="portfolio__wrapper-6">
                                    <div className="portfolio__list-6">
                                        <div className="portfolio__item-6" data-portfitem="1">
                                            <Link to="/project/visitbook">
                                                <img src="/assets/imgs/portfolio/5/visitbook_prj.jpg" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">VisitBook</h4>
                                                    <h5 className="portfolio__date">Контроль відвідувачів БЦ</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="portfolio__item-6" data-portfitem="2">
                                            <Link to="/project/air">
                                                <img src="/assets/imgs/portfolio/5/air_prj.jpg" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">Air</h4>
                                                    <h5 className="portfolio__date">адмінка для управління сайтами житла</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="portfolio__item-6" data-portfitem="3">
                                            <Link to="/project/videoforge">
                                                <img src="/assets/imgs/portfolio/5/videoforge_prj.png" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">Videoforge</h4>
                                                    <h5 className="portfolio__date">Контроль над синхронним відеопотоком</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="portfolio__item-6" data-portfitem="4">
                                            <Link to="/project/cabinet">
                                                <img src="/assets/imgs/portfolio/5/tenant_prj.jpg" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">Tenant Cabinet</h4>
                                                    <h5 className="portfolio__date">кабінет орендаря</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="portfolio__item-6" data-portfitem="5">
                                            <Link to="/project/touchpanels">
                                                <img src="/assets/imgs/portfolio/5/touch_prj.jpg" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">TouchPanels</h4>
                                                    <h5 className="portfolio__date">Побудова маршрутів та надання інформації по ТРЦ</h5>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="portfolio__item-6" data-portfitem="6">
                                            <Link to="/project/crm">
                                                <img src="/assets/imgs/portfolio/5/aircrm_prj.jpg" alt="Portfolio Image" data-speed="0.4" />
                                                <div className="portfolio__content-6">
                                                    <h4 className="portfolio__title-6">Air CRM</h4>
                                                    <h5 className="portfolio__date">система для взаємодії з клієнтами</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Porfolio;