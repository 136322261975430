const Videoforge = (props) => {
    const { updateTitle } = props;

    return (<>
        {updateTitle("Videoforge")}
        <section className="portfolio__detail">
            <div className="portfolio__detail-top">
                <div className="container g-0 line pt-110 pb-130">
                    <span className="line-3"></span>
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come">Videoforge </h2>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                            <div className="portfolio__detail-info">
                                <ul>
                                    <li>Продукт <span>Систме управління Відеопотоком</span></li>
                                    <li>Клієнт <span>ТРЦ Gulliver</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio__detail-thumb black-mask">
                <img src="/assets/imgs/portfolio/detail/videoforge1.jpg"
                     alt="Portfolio Thumbnail" data-speed="auto" />
            </div>

            <div className="portfolio__detail-content">
                <div className="container g-0 line pt-140">
                    <span className="line-3"></span>
                    <div className="block-content">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                <h2 className="portfolio__detail-title title-anim">Videoforge - успіх!</h2>
                            </div>

                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                <div className="portfolio__detail-text">
                                    <p> Сервіс для управління потоком відео на будь-яких єкранах.</p>

                                    <h3>Для бізнесу:</h3>
                                    <ul>
                                        <li>VideoForge може бути цінним інструментом для бізнесу. Він може використовуватися для синхронного відтворення відео на екранах в магазинах, ресторанах, готелях або на інших громадських місцях.</li>
                                    </ul>
                                    <br />
                                    <h3>Для освіти:</h3>
                                    <ul>
                                        <li>VideoForge може використовуватися для синхронного відтворення відео в класах, аудиторіях або інших навчальних приміщеннях.</li>
                                    </ul>
                                    <br />
                                    <h3>Для розваг:</h3>
                                    <ul>
                                        <li>VideoForge може використовуватися для синхронного відтворення відео на екранах в кінотеатрах, концертних залах або на інших розважальних заходах.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Відео на будь-якому екрані:</h3>
                                <ul>
                                    <li><span>Створення плейлистів:</span> VideoForge дозволяє створювати плейлисти відео, які можна синхронізовано відтворювати на декількох екранах.</li>
                                    <br />
                                    <li><span>Управління відтворенням:</span>VideoForge дозволяє керувати відтворенням відео.</li>
                                    <br />
                                    <li><span>Налаштування відтворення:</span>VideoForge дозволяє гнучко налаштовувати відтворення відео.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <div className="iphone-wrap">
                                <video poster="/assets/video/videoforge.mp4" muted="" autoPlay="" loop=""
                                       playsInline="" className="index-intro__video-tag">
                                    <source src="/assets/video/videoforge.mp4" type="video/mp4" />Ваш браузер не підтримує HTML5
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12 vid-col">
                            <div className="video-wrapper">
                                <video poster="/assets/video/videoforge_new.mp4" muted="" autoPlay="" loop=""
                                       playsInline="" className="index-intro__video-tag">
                                    <source src="/assets/video/videoforge_new.mp4" type="video/mp4" />Ваш браузер не підтримує HTML5
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Переваги:</h3>
                                <ul>
                                    <li><span>Простота використання</span>VideoForge має інтуїтивно зрозумілий інтерфейс, який легко освоїти. Це робить його хорошим вибором для користувачів будь-якого рівня досвіду.</li>
                                    <li><span>Широкий спектр функцій</span>VideoForge пропонує широкий спектр функцій, які можуть допомогти користувачам синхронізовано відтворювати відео на декількох екранах.</li>
                                    <li><span>Хмарна інфраструктура</span>сервіс може працювати на віддаленому сервері, що означає, що користувачі можуть створювати та керувати плейлистами відео з будь-якого пристрою, підключеного до Інтернету. Це робить його зручним і гнучким рішенням для управління потоком відео на великих екранах.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <h3 className="modern-title title-anim">Основні переваги</h3>
                    <ol className="videoforge-advantages">
                        <li >
                            <div className="icon"><i className="fa fa-check-square" aria-hidden="true"></i></div>
                            <p className="title">Ефективність</p>
                            <p className="descr">відтворювати відео на декількох екранах, незалежно від їх розташування та конфігурації</p>
                        </li>
                        <li >
                            <div className="icon"><i className="fa fa-arrows-alt" aria-hidden="true"></i></div>
                            <p className="title">Гнучкість</p>
                            <p className="descr">пропонує широкий спектр функцій, які дозволяють користувачам налаштовувати відтворення відео відповідно до своїх потреб</p>
                        </li>
                        <li >
                            <div className="icon"><i className="fa fa-thumbs-up" aria-hidden="true"></i></div>
                            <p className="title">Зручність</p>
                            <p className="descr">має інтуїтивно зрозумілий інтерфейс, який легко освоїти, для користувачів будь-якого рівня досвіду</p>
                        </li>
                        <li >
                            <div className="icon"><i className="fa fa-leaf" aria-hidden="true"></i></div>
                            <p className="title">Надійність</p>
                            <p className="descr">забезпечує безперервне відтворення відео, навіть якщо один із екранів виходить з ладу</p>
                        </li>
                        <li >
                            <div className="icon"><i className="fa fa-shield" aria-hidden="true"></i></div>
                            <p className="title">Безпека</p>
                            <p className="descr">забезпечує безпеку відео, використовуючи найсучасніші методи шифрування та захисту</p>
                        </li>
                        <li >
                            <div className="icon"><i className="fa fa-expand" aria-hidden="true"></i></div>
                            <p className="title">Маштабність</p>
                            <p className="descr">може масштабуватися відповідно до потреб користувачів,  для управління відтворенням відео в будь-якому масштабі</p>
                        </li>
                    </ol>
                    <div className="block-img-text">
                        <img src="/assets/imgs/portfolio/detail/videoforge.webp" alt="Portfolio Image" />
                        <img src="/assets/imgs/portfolio/detail/videoforge2.jpg" alt="Portfolio Image" />
                        <p className="final-text"> VideoForge — це потужний і гнучкий інструмент, який може допомогти користувачам будь-якого рівня досвіду синхронізовано відтворювати відео на декількох екранах. </p>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Videoforge;