import React, {useEffect , useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import BlogPage from "./pages/BlogPage";
import ProjectPage from "./pages/ProjectPage";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

const App = () => {
    const updateTitle = (newTitle) => {
        document.title = newTitle;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home updateTitle={updateTitle} />}/>
                    <Route path="/blogs/:slug" element={<BlogPage updateTitle={updateTitle} />}/>
                    <Route path="/project/:slug" element={<ProjectPage updateTitle={updateTitle} />}/>
                    <Route path="/blogs" element={<Blogs updateTitle={updateTitle} />}/>
                    <Route path="/contact" element={<Contact updateTitle={updateTitle} />}/>
                    <Route path="*" element={<NoPage updateTitle={updateTitle} />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
