const Air = (props) => {
    const { updateTitle } = props;

    return (<>
        { updateTitle("Air - Адмін-систмема управління житлом") }
        <section className="portfolio__detail">
            <div className="portfolio__detail-top">
                <div className="container g-0 line pt-110 pb-130">
                    <span className="line-3"></span>
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come">Air </h2>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12">
                            <div className="portfolio__detail-info">
                                <ul>
                                    <li>Продукт <span>Адмін-систмема управління житлом</span></li>
                                    <li>Клієнт <span>Anbud</span></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio__detail-thumb">
                <img src="/assets/imgs/portfolio/detail/air_bg.jpg"
                     alt="Portfolio Thumbnail" data-speed="auto"/>
            </div>
            <div className="portfolio__detail-content">
                <div className="container g-0 line pt-140">
                    <span className="line-3"></span>
                    <div className="block-content">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                <h2 className="portfolio__detail-title title-anim">Керувати так просто!</h2>
                            </div>
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                <div className="portfolio__detail-text">
                                    <h3>Зручність використання:</h3>
                                    <ul>
                                        <li>Air проста у використанні як для власників нерухомості, так і для
                                            менеджерів. Вона має інтуїтивно зрозумілий інтерфейс, який можна легко
                                            освоїти.
                                        </li>
                                    </ul>
                                    <br/>
                                    <h3>Ефективність:</h3>
                                    <ul>
                                        <li>Air може допомогти підвищити ефективність управління житловими комплексами,
                                            будинками та квартирами. Вона автоматизує багато завдань, таких як
                                            управління бронюванням, сплата рахунків та обслуговування мешканців.
                                        </li>
                                    </ul>
                                    <br/>
                                    <h3>Безпека:</h3>
                                    <ul>
                                        <li>Air забезпечує безпеку даних мешканців. Вона використовує найсучасніші
                                            методи шифрування та захисту.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center" style={{marginBottom: "50px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Веб застосунок:</h3>
                                <p> це хмарне рішення, яке допомагає управляти житловими комплексами, будинками та
                                    квартирами. Воно пропонує широкий спектр функцій, які можуть допомогти власникам
                                    нерухомості, менеджерам та жителям.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 img-col">
                            <img className="portfolio-img iphone" src="/assets/imgs/portfolio/detail/air_mobile.png"
                                 alt="" style={{maxHeight: "600px", width: "auto"}} />
                        </div>
                    </div>

                    <div className="row align-items-center" style={{marginBottom: "50px"}} >
                        <div className="col-lg-6 col-md-12 vid-col">
                            <div className="video-wrapper">
                                <video poster="/assets/video/main_air.mp4" muted="" autoPlay="" loop=""
                                       playsInline="" className="index-intro__video-tag" style={{maxHeight: "300px", width: "auto"}} >
                                    <source src="/assets/video/main_air.mp4" type="video/mp4" />Ваш браузер не підтримує HTML5
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="portfolio__detail-text">
                                <h3>Внутрішня комунікація:</h3>
                                <p>Air можна використовувати для створення та управління внутрішнім контентом, таким як
                                    новини, статті та навчальні матеріали. Це може допомогти вам покращити комунікацію
                                    та співпрацю всередині вашої організації.</p>
                            </div>
                        </div>
                    </div>
                    <h3 className="modern-title title-anim">Основні переваги</h3>
                    <section className="ps-timeline-sec">
                        <div className="container">
                            <ol className="ps-timeline">
                                <li>
                                    <div className="img-handler-top">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <div className="ps-bot">
                                        <h3>Зручність для менеджерів</h3>
                                        <p>Створення звітів про діяльність допомагає менеджерам відстежувати
                                            ефективність управління житловим комплексом.</p>
                                    </div>
                                    <span className="ps-sp-top">01</span>
                                </li>
                                <li>
                                    <div className="img-handler-bot">
                                        <i className="fab fa-connectdevelop"></i>
                                    </div>
                                    <div className="ps-top">
                                        <h3>Можливість інтеграції</h3>
                                        <p>Air можна інтегрувати з іншими системами, такими як CRM-інструменти або
                                            системи управління персоналом. </p>
                                    </div>
                                    <span className="ps-sp-bot">02</span>
                                </li>
                                <li>
                                    <div className="img-handler-top">
                                        <i className="fas fa-window-restore"></i>
                                    </div>
                                    <div className="ps-bot">
                                        <h3>Робота з контентом</h3>
                                        <p>Air дозволяє швидко та легко створювати, редагувати та керувати
                                            контентом.</p>
                                    </div>
                                    <span className="ps-sp-top">03</span>
                                </li>
                                <li>
                                    <div className="img-handler-bot">
                                        <i className="fas fa-vote-yea"></i>
                                    </div>
                                    <div className="ps-top">
                                        <h3>Все й одразу</h3>
                                        <p>Air - це потужний і гнучкий інструмент, який допоможе вам легко та зручно
                                            обслуговувати потрібну вам нішу</p>
                                    </div>
                                    <span className="ps-sp-bot">04</span>
                                </li>
                            </ol>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </>
);
}

export default Air;