import React, {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";


export function BlogPosts(){
    const [posts, setPost] = useState([{}]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    function getOffset(){
        const limit = 6;

        if(page > 1){ return  ((page - 1) * limit); }

        return 0;
    }

    function getLastPage(){
        return  count > page * 6;
    }

    async function loadPosts(){
        let headers = new Headers();
        headers.append("Authorization", `Bearer ixR-NZULqA3fVKQXrTmWvUKP2G6My0vD`);
        let requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        let api = (await fetch(`https://data.firebit.agency/items/blog?meta=filter_count&limit=6&offset=${getOffset()}&filter[status]=published`, requestOptions)).json()
        let apiJson = await api;
        setPost(apiJson.data);
        setCount(apiJson.meta.filter_count);
    }

    useEffect(() => {
        loadPosts();
    }, [page]);

    if (posts === null) {
        console.log('posts is empty');
        return null;
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const dateFormatter = (dateTime) => {
        let d = new Date(dateTime);

        return (d.toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }));
    }

    return (
        <>
            <div className="row reset-grid">
                {
                    posts?.map((item, i) => {
                        return(
                            <div key={i} className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <article className="blog__item">
                                    <div className="blog__img-wrapper">
                                        <Link to={`/blogs/${item.slug}`} >
                                            <div className="img-box">
                                                <img className="image-box__item" src={"https://data.firebit.agency/assets/" + item.image_preview} alt="Blog Thumbnail" />
                                                <img className="image-box__item" src={"https://data.firebit.agency/assets/" + item.image_preview} alt="BLog Thumbnail" />
                                            </div>
                                        </Link>
                                    </div>
                                    <h4 className="blog__meta">{dateFormatter(item.date_created)}</h4>
                                    <h5>
                                        <Link to={`/blogs/${item.slug}`} className="blog__title" >
                                            {item.title}
                                        </Link>
                                    </h5>
                                    <Link to={`/blogs/${item.slug}`} className="blog__btn" >
                                        Читати <span><i className="fa-solid fa-arrow-right"></i></span>
                                    </Link>
                                </article>
                            </div>
                        )
                    })
                }
            </div>
            <div className="navigation">
            {(page > 1) ?
                <button  className="navigation__button prev cta__sub-title" onClick={() => handlePageChange(page - 1)}><i
                    className="fa-solid fa-arrow-left"></i><p data-button={"Попередня"} >Попередня</p></button>
            : '' }
            {(getLastPage()) ?
                <button className="navigation__button next cta__sub-title" onClick={() => handlePageChange(page + 1)}><p data-button={"Наступна"} >Наступна</p> <i
                    className="fa-solid fa-arrow-right"></i></button>
                : '' }
            </div>
        </>
    );
}