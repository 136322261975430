import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Parser } from "html-to-react";

const BlogPage = (props) => {
    const { updateTitle } = props;
    const [page, setPage] = useState({});
    let params = useParams();

    const dateFormatter = (dateTime) => {
        let d = new Date(dateTime);

        return (d.toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }));
    }

    async function loadPage(){
        let headers = new Headers();
        headers.append("Authorization", `Bearer ixR-NZULqA3fVKQXrTmWvUKP2G6My0vD`);
        let requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };

        let api = (await fetch(`https://data.firebit.agency/items/blog?meta=filter_count&limit=1&filter[status]=published&filter[slug]=${params.slug}`, requestOptions)).json()
        let apiJson = await api;
        setPage(apiJson.data[0]);

    }

    useEffect(() => {
        loadPage();
    }, []);

    if (page === null) {
        console.error('posts is empty');
        return null;
    }

    return (<>
        {updateTitle(page.title)}
        <section className="blog__detail">
            <div className="container g-0 line pt-140">
                <span className="line-3"></span>
                <div className="row">
                    <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                        <div className="blog__detail-top">
                            <h2 className="blog__detail-date animation__word_come">{dateFormatter(page.date_created)}</h2>
                            <h3 className="blog__detail-title animation__word_come">{page.title}
                            </h3>
                        </div>
                    </div>
                    <div className="col-xxl-12">
                        <div className="blog__detail-thumb">
                            <img src={"https://data.firebit.agency/assets/" + page.image} alt="Blog Thumbnail" data-speed="0.5" />
                        </div>
                    </div>
                    <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                        <div className="blog__detail-content">
                            {Parser().parse(page.text)}
                        </div>
                        <div className="blog__detail-tags">
                            <ul className="sub-title-anim">Теги:
                                {
                                    page.tags?.map((item, i) => {
                                        return(
                                            <li key={i} href="#">{item}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
};

export default BlogPage;