import React from "react";
import InputMask from 'react-input-mask';
import Default from "../components/Default";

export default class Contact extends Default {

    componentDidMount() {
        this.props.updateTitle("Контакти");
        super.componentDidMount();
    }

    state = {
            formSubmitted: false,
            formRaws: {
                name: "",
                email: "",
                phone: "",
                theme: "",
                message: "",
            },
            formErrors: {
                name: "",
                email: "",
                theme: "",
                message: "",
            }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formRaws: {
                ...prevState.formRaws,
                [name]: value,
            },
        }));
    };

    handleFormSubmit = (e) => {
            e.preventDefault();
             if (this.handleValidation()) {
                this.handleSubmit(e);
             }
        };

    handleSubmit = () => {

        this.setState({ formSubmitted: true });
        const { name, email, phone, theme, message } = this.state.formRaws;
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: "Bearer ixR-NZULqA3fVKQXrTmWvUKP2G6My0vD",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                phone,
                theme,
                message,
            }),
        };

        fetch("https://data.firebit.agency/items/feedbacks", requestOptions)
            .then((response) => response.json())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));

    };
    handleValidation = () => {
        const { name, email, theme, message } = this.state.formRaws;
        const formErrors = {
            name: !name ? "Введіть своє ім'я" : "",
            email: !email ? "Введіть свій email" : "",
            theme: !theme ? "Тема звернення обов'язкова" : "",
            message: !message ? "Текст повідомлення обов'язковий" : "",
        };

        this.setState({ formErrors });

        return Object.values(formErrors).every((error) => !error);

    };



renderForm() {
    return (
        <div className="contact__form">
            <form id="contact-form" action="#" method="POST"
                  onSubmit={this.handleFormSubmit}>
                <div className="row g-3">
                    <div className="col-xxl-6 col-xl-6 col-12">
                        <input type="text" name="name" placeholder="Ім'я *"
                               value={this.state.formRaws.name}
                               onChange={this.handleChange}
                        />
                        <span className="text-danger">{this.state.formErrors.name}</span>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-12">
                        <input type="email" name="email" placeholder="Email *"
                               value={this.state.formRaws.email}
                               onChange={this.handleChange}
                        />
                        <span className="text-danger">{this.state.formErrors.email}</span>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-xxl-6 col-xl-6 col-12">
                        <InputMask
                            mask="389999999999"
                            name="phone"
                            type="text"
                            placeholder="Телефон"
                            value={this.state.formRaws.phone}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-12">
                        <input type="text" name="theme" placeholder="Тема *"
                               value={this.state.formRaws.theme}
                               onChange={this.handleChange}
                        />
                        <span className="text-danger">{this.state.formErrors.theme}</span>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-12">
                        <textarea name="message" placeholder="Повідомлення *"
                                  value={this.state.formRaws.message}
                                  onChange={this.handleChange}
                        />
                        <span className="text-danger">{this.state.formErrors.message}</span>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-12">
                        <div className="btn_wrapper">
                            <button className="wc-btn-primary btn-hover btn-item">
                                <span></span> Відправити <br/>Повідомлення <i
                                className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

renderSendMessage() {
    return (
        <div className="contact__form">
            <h3 className="sub-title-anim-top animation__word_come"> Вітаємо! Тільки що проєкт мрії став на крок ближче <br/>
                Заявку успішно надіслано</h3>
        </div>
    );
}

render()
{
    return <>
        <section className="contact__area-6">
            <div className="container g-0 line pt-120 pb-110">
                <span className="line-3"></span>
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="sec-title-wrapper">
                            <h2 className="sec-title-2 animation__char_come">Давай розробимо щось разом!</h2>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="contact__text">
                            <p>Ми будемо раді відповісти на всі твої запитання та обговорити всі потреби.</p>
                        </div>
                    </div>
                </div>
                <div className="row contact__btm">
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                        <div className="contact__info">
                            <h3 className="sub-title-anim-top animation__word_come">Не зволікайте! <br/>Зв'яжіться
                                з нами!</h3>
                            <ul>
                                <li>Спортивна площа, 1, Київ, Украина, 01023</li>
                                <li><a href="tel:+380443642444" className="phone">+38 (044) 364 24 44</a></li>
                                <li><a href="mailto:info@itok.com.ua"> info@itok.com.ua</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                        {this.state.formSubmitted  ? this.renderSendMessage() : this.renderForm()}
                    </div>
                </div>
            </div>
        </section>
    </>
}
}