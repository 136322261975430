import React from "react";
import Default from "../components/Default";
import { BlogPosts } from "../components/BlogPosts";
import {Link} from "react-router-dom";

class Blogs extends Default{
    componentDidMount() {
        this.props.updateTitle("Блог");
        super.componentDidMount();
    }

    render() {
        return (
            <>
                <section className="blog__area-6 blog__animation">
                    <div className="container g-0 line pt-110 pb-110">
                        <span className="line-3"></span>
                        <div className="row pb-130">
                            <div className="col-xxl-8 col-xl-7 col-lg-6 col-md-6">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-title-2 animation__char_come">Цікаві статті <br />на тему ІТ</h2>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-6">
                                <div className="blog__text">
                                    <p>Цікаві новини та події в сфері ІТ </p>
                                </div>
                            </div>
                        </div>
                        <BlogPosts />
                    </div>
                </section>
                <section className="cta__area">
                    <div className="container line pb-110">
                        <div className="line-3"></div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="cta__content">
                                    <p className="cta__sub-title">зв’язатися з нами</p>
                                    <h2 className="cta__title title-anim">Ми хотіли б дізнатися більше про ваш проект</h2>
                                    <div className="btn_wrapper">
                                        <Link to={'/contact'} className={"wc-btn-primary btn-item btn-hover"}>
                                            <span></span>Давайте поговоримо <i
                                            className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Blogs;